import React, { forwardRef } from 'react';
import { useLogout } from 'react-admin';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    logoutButton: {
        color: "#fff",
        fontWeight: 500,
        "&:hover":{
            color: "#fff",
            backgroundColor: "rgba(0,0,0,0.04)",
        }
    },
});

const MyLogoutButton = forwardRef((props, ref) => {
    const classes = useStyles();
    const logout = useLogout();
    const handleClick = () => logout('/login');
    return (
        <Button
            className={classes.logoutButton}
            color="primary"
            onClick={handleClick}
            ref={ref}
        >
            <span>Logout</span>
        </Button>
    );
});

export default MyLogoutButton;