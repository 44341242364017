import React from 'react';
import { List, Datagrid, TextField, Pagination, FunctionField, DateField, DatagridBody } from 'react-admin';
import { Title } from 'react-admin';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { makeStyles } from '@material-ui/core/styles';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import ModalImage from "react-modal-image";

const useStyles = makeStyles({
    responsive_table: {
        width: "100%",
        overflow: "hidden",
        "& .list-page > div:nth-child(1)": {
            display: "none"
        },
        "& .list-page > div:nth-child(2) > div": {
            boxShadow: "none",
            display: "inline-block",
            width: "100%",
            maxWidth: "100%",
            overflow: "auto",
        },
    },
    wrapper_for_table: {
        overflow: 'auto',
        width: '100%',
        maxWidth: '100%',
        "& > table": {
            tableLayout: 'auto',
            width: 'auto',
            minWidth: '100%',
            "& > tbody > tr > td": {
                padding: '6px',
            },
            "& > thead > tr > th": {
                padding: '6px',
            },
            "& > thead > tr > th > span": {
                whiteSpace: 'nowrap',
                position: 'relative',
                minHeight: '18px',
                display: 'inline-block',
                verticalAlign: 'top',
                padding: '0 22px 0 0',
                "& > svg": {
                    width: '18px',
                    height: '18px',
                    margin: '-9px 0 0',
                    position: 'absolute',
                    top: '50%',
                    right: '0',
                    padding: '0',
                },
                "& > span": {
                    display: 'block',
                    width: '100%',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    fontWeight: 700,
                }
            }
        },
    },
});

const PostPagination = props => (
    <div className="postPagination">
        <Pagination rowsPerPageOptions={[5, 10, 25, 50]} {...props} />
    </div>
);

const MyDatagridRow = ({ record, resource, id, onToggleItem, children, selected, basePath }) => (
    <TableRow key={id}>
        {React.Children.map(children, field => (
            <TableCell key={`${id}-${field.props.source}`}>
                {React.cloneElement(field, {
                    record,
                    basePath,
                    resource,
                })}
            </TableCell>
        ))}
    </TableRow>
);

const MyDatagridBody = props => <DatagridBody {...props} row={<MyDatagridRow />} />;

const MyDatagrid = props => {
    const classes = useStyles();
    return (
        <div className={classes.wrapper_for_table}>
            <Datagrid {...props} body={<MyDatagridBody />} />
        </div>
    );
};

export const Results = props => {
    const classes = useStyles();

    return (
        <div className="ResultsDashboard">
            <Card>
                <Title title="Results" />
                <CardContent>
                    <h1>Results Dashboard</h1>
                    <div className={classes.responsive_table}>
                        <List {...props} bulkActionButtons={false} exporter={false} pagination={<PostPagination />} perPage={25}>
                            <MyDatagrid>
                                {/*
                                <DateField label="Date executed" source="utcExecutedDate" />
                                <DateField label="Date receiving" source="utcReceivedDate" />
                                */}
                                <FunctionField
                                    label="Image"
                                    render={record => {
                                        if(record?.image)
                                            return (<div style={{width: 100}}>
                                                <ModalImage
                                                    small={record.image.thumbnailUrl}
                                                    large={record.image.url}
                                                    hideDownload
                                                />
                                            </div>)
                                        else
                                            return ('-')                              
                                    }}
                                />                       
                                <DateField label="Date" source="utcExecutedDate" locales="en-EN" options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}/>
                                <TextField label="Batch No" source="batchNumber" />
                                <DateField label="Lot No" source="utcLotNumberDate" locales="en-EN" options={{ year: 'numeric', month: 'numeric', day: 'numeric' }}/>
                                <TextField label="Test type" source="type" />
                                {/* <TextField label="Test result" source="resultType" /> */}
                                <TextField label="C Line" source="cLineResult" /> 
                                <TextField label="G Line" source="gLineResult" /> 
                                <TextField label="M Line" source="mLineResult" /> 
                                <FunctionField
                                    sortBy="location.city"
                                    label="Location"
                                    render={record => {
                                        if (record?.location)
                                            // return (`${record.location?.city ? record.location.city : ''} ${record.location?.region ? record.location.region : ''} ${record.location?.country ? record.location.country : ''}`)
                                            return (`${record.location?.city ? record.location.city : ''} ${record.location?.country ? record.location.country : ''}`)
                                        else
                                            return ('-')
                                    }}
                                />
                                {/*  */}
                                <FunctionField
                                    sortBy="deviceInfo.platform"
                                    label="Device info"
                                    render={record => `${record.deviceInfo.platform ? record.deviceInfo.platform : ''} ${record.deviceInfo.vendor ? record.deviceInfo.vendor : ''} ${record.deviceInfo.model ? record.deviceInfo.model : ''} ${record.deviceInfo.osVersion ? record.deviceInfo.osVersion : ''} ${record.deviceInfo.locale ? record.deviceInfo.locale : ''}`}
                                />
                            </MyDatagrid>
                        </List>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};


