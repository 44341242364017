import React from 'react';
import { Admin, Resource } from 'react-admin';
import { Results } from './resultDashboard';
import ReportDashboard from './reportDashboard';
import { Layout, Login } from './layout';
import customResetPassword from './layout/ForgotPassword';
import { Route } from "react-router-dom"

import authProvider from './authProvider';
import dataProvider from './dataProvider'; 

import { createBrowserHistory } from "history";
const customHistory = createBrowserHistory();

const App = () => (
  <Admin
    title=""
    authProvider={authProvider}
    dashboard={ReportDashboard}
    dataProvider={dataProvider}
    layout={Layout}
    loginPage={Login}
    history={customHistory}
    customRoutes={[
      <Route exact path="/forgot-password" component={customResetPassword} noLayout/>,
  ]}    
  >
    <Resource options={{ label: 'Results' }} name="results" list={Results} />
  </Admin>
);

export default App;
