import React from 'react';
import { AppBar } from 'react-admin';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import MyLogoutButton from './logoutButton'

const useStyles = makeStyles({
    flexContainer: {
        '& > header': {
			background: "#14a8ab",
        },
        '& > header > div': {
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "flex-start",
            alignContent: "center",
            alignItems: "center",  
        },
		'& > header > div button:first-child': {
			marginLeft: "0.1666666666666667em",
			marginRight: "0.1666666666666667em",
		},
        '& > header > div > .flexContainerItem': {
            order: "0",
            flex: "1 1 auto",
            alignSelf: "auto",         
        }, 
        '& > header > div > .flexContainerItem + *': {
            display: 'none !important'
        }                   
    },
    title: {
        flex: 1,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        '& > span:nth-child(2)': {
            display: 'none'
        },
    },
    spacer: {
        flex: 1,
    }
});

const CustomAppBar = (props: any) => {
    const classes = useStyles();
    return (
        <div className={classes.flexContainer} >
            <AppBar {...props} userMenu={<MyLogoutButton />}>
                <div className="flexContainerItem">
                    <Typography
                        variant="h6"
                        color="inherit"
                        className={classes.title}
                        id="react-admin-title"
                    />
                    <span className={classes.spacer} />
                </div>
            </AppBar>
        </div>
    );
};

export default CustomAppBar;
