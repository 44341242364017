import { stringify } from 'query-string';
import { apiUrl } from '../constants';
import axios from 'axios';
import createAuthRefreshInterceptor from 'axios-auth-refresh';
//import jwt_decode from 'jwt-decode-ie';

const refreshAuthLogic = failedRequest => axios.post(`${apiUrl}/auth/admin/refresh?${stringify({'refreshToken': localStorage.getItem('refreshToken')})}`, { headers: { 'accept': 'application/json' } })
    .then(tokenRefreshResponse => {
        localStorage.setItem('accessToken', tokenRefreshResponse.data.data.accessToken);
        localStorage.setItem('refreshToken', tokenRefreshResponse.data.data.refreshToken);
        localStorage.setItem('tokenType', tokenRefreshResponse.data.data.tokenType);
        localStorage.setItem('role', tokenRefreshResponse.data.data.role);
        failedRequest.response.config.headers['Authorization'] = `${tokenRefreshResponse.data.data.tokenType} ${tokenRefreshResponse.data.data.accessToken}`;
        return Promise.resolve();
    }).catch( error => {
        console.log('error=', error);
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenType');
        localStorage.removeItem('role');
        return Promise.reject()
    });

createAuthRefreshInterceptor(axios, refreshAuthLogic);

function resourceSwitch(param) {
    switch (param) {
        case 'results':
            return 'tests';
        default:
            return param;
    }
}

export default {
    getList: async (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            'Sort.Field': field,
            'Sort.Order': order,
            'Page': page,
            'PerPage': perPage,
        };
        const apiRes = resourceSwitch(resource);
        const url = `${apiUrl}/${apiRes}?${stringify(query)}`;
        const response = await axios.get(url, { headers: { 'accept': 'application/json', 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}` } });
        return ({
            data: response.data.data.items,
            total: response.data.data.totalItemsCount,
        });
    },
    getAnalytics: async () => {
        let data;
        await axios.get(`${apiUrl}/analytics`, { headers: { 'accept': 'application/json', 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}` } })
            .then(function (response) {
                data = response.data;
            })
        return { data };
    },
    getRegions: async () => {
        let data;
        await axios.get(`${apiUrl}/analytics/regions`, { headers: { 'accept': 'application/json', 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}` } })
            .then(function (response) {
                data = response.data;
            })
        return { data };
    },
    getDates: async () => {
        let data;
        await axios.get(`${apiUrl}/analytics/dates`, { headers: { 'accept': 'application/json', 'Authorization': `${localStorage.getItem('tokenType')} ${localStorage.getItem('accessToken')}` } })
            .then(function (response) {
                data = response.data;
            })
        return { data };
    },
};
