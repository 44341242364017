import { apiUrl } from '../constants';
import axios from 'axios';
//import jwt_decode from 'jwt-decode-ie';

export default {

    login: async ({ username, password }) => {
        let data = ({ "email": username, "password": password });
        const res = await axios.post(`${apiUrl}/auth/admin`, data, {
            skipAuthRefresh: true, 
            headers: {
                'accept': 'application/json',
                'Content-Type': 'application/json',
            }
        });

        if (res.data.error || res.status !== 200) {
            //throw new Error(res.data.error);
            return Promise.reject();
        }
        else {
            const accessToken = res.data.data.accessToken;
            const refreshToken = res.data.data.refreshToken;
            const tokenType = res.data.data.tokenType;
            const role = res.data.data.role;
            localStorage.setItem('accessToken', accessToken);
            localStorage.setItem('refreshToken', refreshToken);
            localStorage.setItem('tokenType', tokenType);
            localStorage.setItem('role', role);
            return Promise.resolve();
        }
    },
    logout: () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('tokenType');
        localStorage.removeItem('role');
        return Promise.resolve();
    },
    checkError: (error) => {
        const status = error?.response.status;
        if (status === 403) {
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            localStorage.removeItem('tokenType');
            localStorage.removeItem('role');
            return Promise.reject({ redirectTo: '/login' });
        }
        return Promise.resolve();
    },
    checkAuth: () => {
        return localStorage.getItem('accessToken') ? Promise.resolve() : Promise.reject({ redirectTo: '/login' });
    },
    getPermissions: () => {
        const role = localStorage.getItem('role');
        return role ? Promise.resolve(role) : Promise.reject();
    }

};