import React from 'react';
import { Layout } from 'react-admin';
import AppBar from './AppBar';
import Menu from './Menu';
import { useSelector } from 'react-redux';
import { ReduxState } from 'ra-core';

interface AppState extends ReduxState {}

export default (props: any) => {
    const open = useSelector(
        (state: AppState) => state.admin.ui.sidebarOpen
    );
    const openClass = open ? ' open' : '';
    return (
        <div className={`mainLayout${openClass}`}>
            <Layout
                {...props}
                appBar={AppBar}
                menu={Menu}
            />
         </div>
    );
};
