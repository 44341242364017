import React, { FC } from 'react';
import { ReduxState } from 'ra-core';
import { useSelector } from 'react-redux';
import { DashboardMenuItem, MenuItemLink } from 'react-admin';
import TableChartIcon from '@material-ui/icons/TableChart';
import { makeStyles } from '@material-ui/core/styles';

interface AppState extends ReduxState {}

interface Props {
    dense: boolean;
    onMenuClick: () => void;
}

const useStyles = makeStyles({
    mainMenu: {
        padding: 0,
        margin: 0,
        width: "100%",
        position: "relative",
        left: 0,
        "& a": {
            padding: "6px 16px",
            whiteSpace: "nowrap",
            overflow: "hidden",
            width: "100%",
            textOverflow: "ellipsis",
            display: "block",
            fontSize: "16px",
            lineHeight: "36px",
            minHeight: "48px",
            position: "relative",
        },
        "& a[aria-current=page]": {
            background: "#fff",
            color: "#14a8ab"
        },
        "& a > div": {
            display: "inline-block",
            width: "40px",
            height: "36px",
            verticalAlign: "top",
            margin: 0,
            padding: 0
        },
        "& a > div svg": {
            display: "block",
            width: "24px",
            height: "24px",
            margin: "6px 0",
            padding: 0
        }
    },
});

const Menu: FC<Props> = ({ onMenuClick, dense }) => {

    const classes = useStyles();
    const open = useSelector(
        (state: AppState) => state.admin.ui.sidebarOpen
    );

    return (
        <div className={classes.mainMenu}>
            <DashboardMenuItem primaryText={"Report"} onClick={onMenuClick} sidebarIsOpen={open} />
            <MenuItemLink
                to={`/results`}
                primaryText={"Results"}
                onClick={onMenuClick}
                sidebarIsOpen={open}
                dense={dense}
                leftIcon={<TableChartIcon/>}
            />
        </div>
    );
};

export default Menu;
