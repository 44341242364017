import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import EmailIcon from '@material-ui/icons/Email';
import CodeIcon from '@material-ui/icons/Code';
import { Form } from 'react-final-form';
import TextField from '@material-ui/core/TextField';
import CircularProgress from '@material-ui/core/CircularProgress';
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link';
import ThumbUpIcon from '@material-ui/icons/ThumbUp';
import Snackbar from '@material-ui/core/Snackbar';
import Placeholder from '../assets/svg/coronavirus.svg';
import { apiUrl } from '../constants';
import axios from 'axios';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        width: "100%",
    },
    beforeCard: {
        width: "calc(100% - 450px)",
        maxWidth: "calc(100% - 450px)",
        minHeight: '100vh',
        background: `url(${Placeholder})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 200%',
        alignSelf: 'stretch',
        backgroundPosition: '100% 100%',
        backgroundColor: '#14a8ab',
    },
    card: {
        width: "450px",
        maxWidth: "450px",
        padding: '6em 0',
        minHeight: '100vh',
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        borderRadius: 0,
        alignSelf: 'stretch',
    },
    avatar: {
        margin: '1em 2em',

        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: "#14a8ab",
    },
    marginBox: {
        margin: '1em 2em',
    },
    hint: {
        margin: '1em 2em',
        display: 'flex',
        justifyContent: 'center',
        color: "#999",
    },
    hint2: {
        margin: '1em 0',
        display: 'flex',
        justifyContent: 'center',
        color: "#999",
    },
    form: {
        padding: 0,
    },
    input: {
        margin: '1em 0',
    },
    link: {
        margin: '2em 0 0',
    },
    actions: {
        padding: '2em 0 0',
        '& button .circular': {
            marginRight: '0.75em',
        }
    },
    sign_in: {
        background: '#14a8ab',
        '&:hover': {
            background: '#139da0',
        }
    },
    Snackbar: {
        "& > div > div": {
            color: "#fff",
            background: "#e47274",
        },
    },
});

const ForgotPassword = () => {
    const classes = useStyles();

    const [stepNumber, setStepNumber] = useState(1);

    const [loading, setLoading] = useState(false);

    const [email, setEmail] = useState('');
    const [verificationCode, setVerificationCode] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setOpen(false);
    };

    const handleExited = () => {
        setOpen(false);
    };

    const handleTextFieldChange = (event) => {

        switch (event.target.name) {
            case 'email':
                setEmail(event.target.value);
                break;
            case 'verificationCode':
                setVerificationCode(event.target.value);
                break;
            case 'password':
                setPassword(event.target.value);
                break;
            case 'confirmPassword':
                setConfirmPassword(event.target.value);
                break;
            default:
                break;
        }
    }

    function handleValidateForm1() {
        var patternEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (email.length > 0 && patternEmail.test(email)) {
            return false;
        } else {
            return true;
        }
    };

    function handleValidateForm2() {
        var patternPassword = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*_])(?=.{6,})/;
        return { error: !((verificationCode.length > 0) && patternPassword.test(password) && (password === confirmPassword)), fields: { verificationCode: !(verificationCode.length > 0), password: !patternPassword.test(password), confirmPassword: !(password === confirmPassword) } };
    };

    const handleSubmitStep1 = () => {

        const forgotpassword = async () => {

            setLoading(true);

            const query = {
                "email": email,
            }
            const url = `${apiUrl}/users/forgot-password`;
            await axios.post(url, query, { headers: { 'accept': 'application/json', 'Content-Type': 'application/json' } })
                .then(response => {
                    setStepNumber(2);

                })
                .catch(error => {
                    if (error?.response) {
                        setSnackbarMessage(`Request failed with status code ${error.response.status} (${error.response.statusText})`);
                        setOpen(true);
                    }                    
                });

            setLoading(false);

        }

        forgotpassword();

    }

    const handleSubmitStep2 = () => {

        const setnewpassword = async () => {

            setLoading(true);

            const query = {
                "email": email,
                "password": password,
                "verificationCode": parseInt(verificationCode),                       
            }
            const url = `${apiUrl}/users/set-new-password`;
            await axios.post(url, query, { headers: { 'accept': 'application/json', 'Content-Type': 'application/json' } })
                .then(response => {
                    setStepNumber(3);
                })
                .catch(error => {
                    if (error?.response) {
                        setSnackbarMessage(`Request failed with status code ${error.response.status} (${error.response.statusText})`);
                        setOpen(true);   
                    }                 
                });

                setLoading(false);

        }

        setnewpassword();
        
    }

    return (

        <div className={classes.form} >
            <div className={classes.main}>
                <div className={classes.beforeCard} />
                <div className={classes.card}>
                    <div className={classes.form}>
                        <div className={classes.marginBox} >

                            <div className={classes.Snackbar}>
                                <Snackbar
                                    anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                    open={open}
                                    autoHideDuration={4000}
                                    onClose={handleClose}
                                    onExited={handleExited}
                                    message={snackbarMessage}
                                    key={snackbarMessage ? snackbarMessage.key : undefined}
                                />
                            </div>

                            {(stepNumber === 1) && (

                                <Form onSubmit={handleSubmitStep1}>
                                    {props => (
                                        <form onSubmit={props.handleSubmit} noValidate>
                                            <div className={classes.avatar}>
                                                <Avatar className={classes.icon}>
                                                    <EmailIcon />
                                                </Avatar>
                                            </div>
                                            <div className={classes.hint}>
                                                Reset Your Password
                                            </div>
                                            <div className={classes.hint2}>
                                                Please provide your account email address to request a password reset code.
                                                You will receive your a code to your email address if it is valid.
                                                This code will be valid for an hour.
                                            </div>
                                            <div className={classes.input}>
                                                <TextField name="email" error={handleValidateForm1()} helperText={handleValidateForm1() ? 'Email field is required and must be valid' : ''} type="text" label="Email" disabled={loading} onChange={handleTextFieldChange} fullWidth value={email} />
                                            </div>
                                            <div className={classes.actions}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    fullWidth
                                                    className={classes.sign_in}
                                                    disabled={loading || handleValidateForm1()}
                                                >
                                                    {loading && (
                                                        <CircularProgress className={"circular"} size={25} thickness={2} />
                                                    )}
                                                    Request Reset Code
                                                 </Button>
                                            </div>

                                            <div className={classes.form}>
                                                <div className={classes.link}>
                                                    <div className="flex_link_col">
                                                        <div>
                                                            &nbsp;
                                                        </div>
                                                        <div>
                                                            <Link href="/login" variant="body2">
                                                                Sing In
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    )}
                                </Form>

                            )}

                            {(stepNumber === 2) && (

                                <Form onSubmit={handleSubmitStep2} >
                                    {props => (
                                        <form onSubmit={props.handleSubmit} noValidate>

                                            <div className={classes.avatar}>
                                                <Avatar className={classes.icon}>
                                                    <CodeIcon />
                                                </Avatar>
                                            </div>
                                            <div className={classes.hint}>
                                                Reset Your Password
                                            </div>
                                            <div className={classes.hint2}>
                                                Input the code sent to your email address.
                                                And input a new password.
                                                Your password should contain at least 1 uppercase letter, 1 lowercase, 1 number, 1 special symbol (!@#$%^&*_) and at least 6 characters.
                                            </div>
                                            <div className={classes.input}>
                                                <TextField name="verificationCode" error={handleValidateForm2().fields.verificationCode} helperText={handleValidateForm2() ? 'Code field is required (only numbers)' : ''} type="text" label="Code" disabled={loading} onChange={handleTextFieldChange} fullWidth value={verificationCode} />
                                            </div>
                                            <div className={classes.input}>
                                                <TextField name="password" type="password" error={handleValidateForm2().fields.password} helperText={handleValidateForm2() ? 'Password field is required and should contain at least 1 uppercase letter, 1 lowercase, 1 number, 1 special symbol (!@#$%^&*_) and at least 6 characters' : ''} label="Password" disabled={loading} onChange={handleTextFieldChange} fullWidth value={password} />
                                            </div>

                                            <div className={classes.input}>
                                                <TextField name="confirmPassword" error={handleValidateForm2().fields.confirmPassword} helperText={handleValidateForm2() ? 'The password and confirm password fields do not match' : ''} type="password" label="Confirm password" disabled={loading} onChange={handleTextFieldChange} fullWidth value={confirmPassword} />
                                            </div>

                                            <div className={classes.actions}>
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    color="primary"
                                                    fullWidth
                                                    className={classes.sign_in}
                                                    disabled={loading || handleValidateForm2().error}
                                                >
                                                    {loading && (
                                                        <CircularProgress className={"circular"} size={25} thickness={2} />
                                                    )}
                                                Reset Password
                                            </Button>
                                            </div>

                                            <div className={classes.form}>
                                                <div className={classes.link}>
                                                    <div className="flex_link_col">
                                                        <div>
                                                            <Link href="/forgot-password" variant="body2">
                                                                Start Again
                                                            </Link>
                                                        </div>
                                                        <div>
                                                            <Link href="/login" variant="body2">
                                                                Sing In
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </form>
                                    )}
                                </Form>

                            )}


                            {(stepNumber === 3) && (

                                <div className={classes.form}>
                                    <div className={classes.avatar}>
                                        <Avatar className={classes.icon}>
                                            <ThumbUpIcon />
                                        </Avatar>
                                    </div>
                                    <div className={classes.hint}>
                                        &nbsp;
                                        </div>
                                    <div className={classes.hint2}>
                                        Your password has been successfully changed. Please login to admin panel.
                                        </div>

                                    <div className={classes.link}>
                                        <div className="flex_link_col">
                                            <div>
                                                &nbsp;
                                                    </div>
                                            <div>
                                                <Link href="/login" variant="body2">
                                                    Sing In
                                                        </Link>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            )}

                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
};

export default ForgotPassword;