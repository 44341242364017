import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { Title } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

import CustomActiveShapePieChart from './CustomActiveShapePieChart';
import SimpleBarChart from './SimpleBarChart';
import BarChartWithMultiXAxis from './BarChartWithMultiXAxis';
import { 
    Negative,	NegativeText,
    GLineLow,	GLineLowText,
    GLineMid,	GLineMidText,
    GLineHigh,	GLineHighText,
    MLineLow,	MLineLowText,
    MLineMid,	MLineMidText,
    MLineHigh,	MLineHighText,
    Failed,		FailedText,
} from '../constants';
import { CircularProgress } from '@material-ui/core';
import Snackbar from '@material-ui/core/Snackbar';

import { useDataProvider } from 'react-admin';

const useStyles = makeStyles({
    ReportDashboard: {
        width: "100%",
        overflow: "hidden",
        "& > div": {
            boxShadow: "none",
            border: 0,
        },
    },
    CircularProgress: {
        minHeight: "100px",
        display: "flex",
        flexDirection: "row",
        flexWrap: "nowrap",
        justifyContent: "center",
        alignContent: "center",
        alignItems: "center",
    },
    Snackbar: {
        "& > div > div": {
            color: "#fff",
            background: "#e47274",
        },
    }
});

export default () => {

    const dataProvider = useDataProvider();
    const classes = useStyles();
    const [analyticsIsLoaded, setAnalyticsIsLoaded] = useState(true);
    const [analytics, setAnalytics] = useState();
    const [regionsIsLoaded, setRegionsIsLoaded] = useState(true);
    const [regions, setRegions] = useState();
    const [datesIsLoaded, setDatesIsLoaded] = useState(true);
    const [dates, setDates] = useState();

    const [open, setOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

        setOpen(false);
    };

    const handleExited = () => {
        setOpen(false);
    };

    useEffect(() => {

        if (localStorage.getItem('accessToken')) {

            const fetchData = async () => {
                await dataProvider.getAnalytics()
                    .then(({ data }) => {
                        setAnalyticsIsLoaded(false);
                        setAnalytics(data.data);
                    })
                    .catch(error => {
                        setAnalyticsIsLoaded(false);
                        setAnalytics();
                        if (error?.response) {
                            setSnackbarMessage(`Request failed with status code ${error.response.status} (${error.response.statusText})`);
                            setOpen(true);
                        }
                    });


                await dataProvider.getRegions()
                .then(({ data }) => {
                    setRegionsIsLoaded(false);
                    setRegions(data.data.slice(0, 5));
                })
                .catch(error => {
                    setRegionsIsLoaded(false);
                    setRegions();
                    if (error?.response) {
                        setSnackbarMessage(`Request failed with status code ${error.response.status} (${error.response.statusText})`);
                        setOpen(true);
                    }
                });

                await dataProvider.getDates()
                .then(({ data }) => {
                    setDatesIsLoaded(false);
                    setDates(data.data);
                })
                .catch(error => {
                    setDatesIsLoaded(false);
                    setDates();
                    if (error?.response) {
                        setSnackbarMessage(`Request failed with status code ${error.response.status} (${error.response.statusText})`);
                        setOpen(true);
                    }
                });
            }
            fetchData();            

        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="ReportDashboard">
            <div className={classes.ReportDashboard}>
                <Card>
                    <Title title="Report" />
                    <CardContent>

                        <div className={classes.Snackbar}>
                            <Snackbar
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                                open={open}
                                autoHideDuration={4000}
                                onClose={handleClose}
                                onExited={handleExited}
                                message={snackbarMessage}
                                key={snackbarMessage ? snackbarMessage.key : undefined}
                            />
                        </div>
                        {
                            analyticsIsLoaded ?
                                (
                                    <div className="dashboard_sections">
                                        <h1>Report Dashboard</h1>
                                        <div>
                                            <div className={classes.CircularProgress}>
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                analytics && analytics.totalTestsCount ?
                                    (

                                        <>
                                            <div className="dashboard_sections">
                                                <h1>Report Dashboard</h1>
                                                <div>
                                                    <p>
                                                        Total number of received results:
                                                        {' '}
                                                        <strong>{analytics.totalTestsCount}</strong>
                                                    </p>
                                                    <p>
                                                        Total regions (countries): 
                                                        {' '}
                                                        <strong>{analytics.totalRegionsCount ? analytics.totalRegionsCount : '-'}</strong>
                                                    </p>
                                                    <p>
                                                        Platforms ratio - iOS/Android:
                                                        {' '}
                                                        <strong>
                                                                {Math.round(analytics.iosTestsCount * 10000 / analytics.totalTestsCount) / 100}
                                                            /
                                                            {Math.round(analytics.androidTestsCount * 10000 / analytics.totalTestsCount) / 100}
                                                            %
                                                        </strong>
                                                    </p>
                                                </div>
                                            </div>

                                            <div className="dashboard_sections">
                                                <h2>Results</h2>
                                                <div className="CustomActiveShapePieChart">
                                                    <div>

                                                        <div className="lenend">
                                                            <p>
                                                                <span className="customLegend" style={{ background: Negative }}></span>
                                                                <span>{NegativeText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.negative} ({Math.round(analytics.results.negative * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>     
                                                            <p>
                                                                <span className="customLegend" style={{ background: GLineLow }}></span>
                                                                <span>{GLineLowText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.gLineLow} ({Math.round(analytics.results.gLineLow * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>  
                                                            <p>
                                                                <span className="customLegend" style={{ background: GLineMid }}></span>
                                                                <span>{GLineMidText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.gLineMedium} ({Math.round(analytics.results.gLineMedium * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p> 
                                                            <p>
                                                                <span className="customLegend" style={{ background: GLineHigh }}></span>
                                                                <span>{GLineHighText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.gLineHigh} ({Math.round(analytics.results.gLineHigh * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>  
                                                            <p>
                                                                <span className="customLegend" style={{ background: MLineLow }}></span>
                                                                <span>{MLineLowText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.mLineLow} ({Math.round(analytics.results.mLineLow * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p> 
                                                            <p>
                                                                <span className="customLegend" style={{ background: MLineMid }}></span>
                                                                <span>{MLineMidText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.mLineMedium} ({Math.round(analytics.results.mLineMedium * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>
                                                            <p>
                                                                <span className="customLegend" style={{ background: MLineHigh }}></span>
                                                                <span>{MLineHighText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.mLineHigh} ({Math.round(analytics.results.mLineHigh * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>   
                                                            <p>
                                                                <span className="customLegend" style={{ background: Failed }}></span>
                                                                <span>{FailedText}:</span>
                                                                {' '}
                                                                <strong>{analytics.results.failed} ({Math.round(analytics.results.failed * 10000 / analytics.totalTestsCount) / 100}%)</strong>
                                                            </p>                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   

                                                        </div>

                                                    </div>
                                                    <div>
                                                        <CustomActiveShapePieChart data={[
                                                            { name: NegativeText, value: analytics.results.negative, fill: Negative },
                                                            { name: GLineLowText, value: analytics.results.gLineLow, fill: GLineLow },
                                                            { name: GLineMidText, value: analytics.results.gLineMedium, fill: GLineMid },
                                                            { name: GLineHighText, value: analytics.results.gLineHigh, fill: GLineHigh },
                                                            { name: MLineLowText, value: analytics.results.mLineLow, fill: MLineLow },
                                                            { name: MLineMidText, value: analytics.results.mLineMedium, fill: MLineMid },
                                                            { name: MLineHighText, value: analytics.results.mLineHigh, fill: MLineHigh },
                                                            { name: FailedText, value: analytics.results.failed, fill: Failed },
                                                        ]} />
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    )
                                    :
                                    (
                                        <div className="dashboard_sections">
                                            <h1>Report Dashboard</h1>
                                            <div>
                                                <p>No data available</p>
                                            </div>
                                        </div>
                                    )
                        }


                        {
                            datesIsLoaded ?
                                (
                                    <div className="dashboard_sections">
                                        <h1>Dynamics</h1>
                                        <div>
                                            <div className={classes.CircularProgress}>
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    </div>
                                )
                                :
                                dates && dates.length ?
                                    (


                                        <div className="dashboard_sections">
                                            <h2>Dynamics</h2>
                                            <div>
                                                <BarChartWithMultiXAxis data={dates.reverse().map((i) => ({ data: `${i.year} Q${i.quarter}`, results: i.results }))} />
                                            </div>
                                        </div>
                                    )
                                    :
                                    (
                                        <div className="dashboard_sections">
                                            <h2>Dynamics</h2>
                                            <div>
                                                <p>No data available</p>
                                            </div>
                                        </div>

                                    )
                        }

                        {
                            regionsIsLoaded ?
                                (
                                    <div className="dashboard_sections">
                                        <h1>Regions</h1>
                                        <div>
                                            <div className={classes.CircularProgress}>
                                                <CircularProgress />
                                            </div>
                                        </div>
                                    </div>
                                ) :
                                regions && regions.length ?
                                    (


                                        <div className="dashboard_sections">
                                            <h2>Regions</h2>
                                            <div>

                                                <SimpleBarChart data={regions.map((i) => ({ region: `${i?.region ? i.region : 'Undefined'}`, results: i.results }))} />
                                            </div>
                                        </div>

                                    )
                                    :
                                    (
                                        <div className="dashboard_sections">
                                            <h2>Regions</h2>
                                            <div>
                                                <p>No data available</p>
                                            </div>
                                        </div>
                                    )
                        }

                    </CardContent>
                </Card>
            </div>
        </div>
    );
};
