import React, { PureComponent } from 'react';
// Brush, 
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import ContainerDimensions from 'react-container-dimensions';
import { 
    Negative,	NegativeText,
    GLineLow,	GLineLowText,
    GLineMid,	GLineMidText,
    GLineHigh,	GLineHighText,
    MLineLow,	MLineLowText,
    MLineMid,	MLineMidText,
    MLineHigh,	MLineHighText,
    Failed,		FailedText,
} from '../constants';
export default class SimpleBarChart extends PureComponent {
    render() {
        return (
            <ContainerDimensions>
                {({ width }) =>
                    <div style={{ width: '100%', height: 400 }}>
                        <ResponsiveContainer>
                            <BarChart
                                data={this.props.data}
                                margin={{ top: 0, right: 0, left: 0, bottom: 0 }}
                            >
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="region" />
                                <YAxis width={60} />
                                <Tooltip contentStyle={{ color: '#333' }} itemStyle={{ color: '#999', padding: 0 }} cursor={{ fill: 'rgba(206, 206, 206, 0.2)' }} />
                                <Legend verticalAlign="top" wrapperStyle={{ lineHeight: '40px', fontSize: '14px' }} />
                                {/* <Brush x={100} y={370} width={(width - 200)} dataKey='region' height={30} stroke="#999" /> */}
                                <Bar dataKey="results.negative" stackId="q" fill={Negative} name={NegativeText} />
                                <Bar dataKey="results.gLineLow" stackId="w" fill={GLineLow} name={GLineLowText} />
                                <Bar dataKey="results.gLineMedium" stackId="e" fill={GLineMid} name={GLineMidText} />
                                <Bar dataKey="results.gLineHigh" stackId="r" fill={GLineHigh} name={GLineHighText} />
                                <Bar dataKey="results.mLineLow" stackId="t" fill={MLineLow} name={MLineLowText} />
                                <Bar dataKey="results.mLineMedium" stackId="y" fill={MLineMid} name={MLineMidText} />
                                <Bar dataKey="results.mLineHigh" stackId="u" fill={MLineHigh} name={MLineHighText} />
                                <Bar dataKey="results.failed" stackId="i" fill={Failed} name={FailedText} />
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                }
            </ContainerDimensions>
        );
    }
}
