export const Failed = "#c4c4c4";
export const Negative = "#518eeb";
export const GLineLow = "#d55cff";
export const GLineMid = "#ae2add";
export const GLineHigh = "#850fae";
export const MLineLow = "#ff6d40";
export const MLineMid = "#ea5324";
export const MLineHigh = "#da3502";

export const FailedText = "Failed";
export const NegativeText = "Negative";
export const GLineLowText = "G line low";
export const GLineMidText = "G line mid";
export const GLineHighText = "G line high";
export const MLineLowText = "M line low";
export const MLineMidText = "M line mid";
export const MLineHighText = "M line high";

export const apiUrl = process.env.REACT_APP_API_URL;