import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Field, withTypes } from 'react-final-form';
import { useLocation } from 'react-router-dom';

import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import LockIcon from '@material-ui/icons/Lock';

import { Notification } from 'react-admin';
//import { useTranslate, useLogin, useNotify } from 'ra-core';
import { useLogin, useNotify } from 'ra-core';

//const randomNumber = Math.floor((Math.random() * 10) + 1);
//const Placeholder = require(`../assets/img/placeholder/${randomNumber}.jpg`);
import Placeholder from '../assets/svg/coronavirus.svg';
import Link from '@material-ui/core/Link';

const useStyles = makeStyles({
    main: {
        display: 'flex',
        flexDirection: 'row',
        minHeight: '100vh',
        alignItems: 'center',
        justifyContent: 'flex-start',
        alignContent: 'stretch',
        width: "100%",
    },
    beforeCard: {
        width: "calc(100% - 450px)",
        maxWidth: "calc(100% - 450px)",
        minHeight: '100vh',
        background: `url(${Placeholder})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto 200%',
        alignSelf: 'stretch',   
        backgroundPosition: '100% 100%',
		backgroundColor: '#14a8ab',
    },
    card: {
        width: "450px",
        maxWidth: "450px",
        padding: '6em 0',
        minHeight: '100vh',
        boxShadow: "0px 3px 5px -1px rgba(0,0,0,0.2), 0px 6px 10px 0px rgba(0,0,0,0.14), 0px 1px 18px 0px rgba(0,0,0,0.12)",
        borderRadius: 0,
        alignSelf: 'stretch',        
    },
    avatar: {
        margin: '1em 2em',

        display: 'flex',
        justifyContent: 'center',
    },
    icon: {
        backgroundColor: "#14a8ab",
    },
    hint: {
        margin: '1em 2em',
        display: 'flex',
        justifyContent: 'center',
        color: "#999",
    },
    form: {
        padding: 0,
    },
    input: {
        margin: '1em 2em',
    },
    link: {
        margin: '2em 2em 0' ,
    },    
    actions: {
        padding: '2em 2em 0',
        '& button .circular': {
            marginRight: '0.75em',
        }   
    },
    sign_in: {
        background: '#14a8ab',
        '&:hover': {
            background: '#139da0',
        }
    },
});

const renderInput = ({
    meta: { touched, error } = { touched: false, error: undefined },
    input: { ...inputProps },
    ...props
}) => (
    <TextField
        error={!!(touched && error)}
        helperText={touched && error}
        {...inputProps}
        {...props}
        fullWidth
    />
);

interface FormValues {
    username?: string;
    password?: string;
}

const { Form } = withTypes<FormValues>();

const Login = () => {
    const [loading, setLoading] = useState(false);
    //const translate = useTranslate();
    const classes = useStyles();
    const notify = useNotify();
    const login = useLogin();
    const location = useLocation<{ nextPathname: string } | null>();

    const handleSubmit = (auth: FormValues) => {
        setLoading(true);
        login(auth, location.state ? location.state.nextPathname : '/').catch(
            /*
            (error: Error) => {
                setLoading(false);
                notify( typeof error === 'string' ? error : typeof error === 'undefined' || !error.message ? 'ra.auth.sign_in_error' : error.message, 'warning' )
                );
            }
            */
           (error) => {
                setLoading(false);
                notify( error?.response ? `Request failed with status code ${error.response.status} (${error.response.statusText})` : error.message, 'warning' );
            }           
        );
    };

    const validate = (values: FormValues) => {
        const errors: FormValues = {};
        if (!values.username) {
            errors.username = 'Email field is required';
        }
        if (!values.password) {
            errors.password = 'Password field is required';
        }
        return errors;
    };

    return (
        <Form
            onSubmit={handleSubmit}
            validate={validate}
            render={({ handleSubmit }) => (
                <form className={classes.form} onSubmit={handleSubmit} noValidate>
                    <div className={classes.main}>
                        <div className={classes.beforeCard} />
                        <Card className={classes.card}>
                            <div className={classes.avatar}>
                                <Avatar className={classes.icon}>
                                    <LockIcon />
                                </Avatar>
                            </div>
                            <div className={classes.form}>
                                <div className={classes.input}>
                                    <Field
                                        autoFocus
                                        name="username"
                                        // @ts-ignore
                                        component={renderInput}
                                        //label={translate('ra.auth.username')}
                                        label={'Email'}
                                        disabled={loading}
                                    />
                                </div>
                                <div className={classes.input}>
                                    <Field
                                        name="password"
                                        // @ts-ignore
                                        component={renderInput}
                                        //label={translate('ra.auth.password')}
                                        label={'Password'}
                                        type="password"
                                        disabled={loading}
                                    />
                                </div>
                            </div>

                            <CardActions className={classes.actions}>
                                <Button
                                    variant="contained"
                                    type="submit"
                                    color="primary"
                                    disabled={loading}
                                    fullWidth
                                    className={classes.sign_in}
                                >
                                    {loading && (
                                        <CircularProgress className={"circular"} size={25} thickness={2} />
                                    )}
                                    {/*translate('ra.auth.sign_in')*/}
                                    Sign in
                                </Button>
                            </CardActions>

                            <div className={classes.form}>
                                <div className={classes.link}>
                                    <Link href="/forgot-password" variant="body2">
                                        Forgot Password?
                                    </Link>
                                </div>
                            </div>

                        </Card>
                        <Notification />
                    </div>
                </form>
            )}
        />
    );
};

Login.propTypes = {
    authProvider: PropTypes.func,
    previousRoute: PropTypes.string,
};

export default Login;
